import { Dispatch, SetStateAction } from 'react';
import { HttpFetchResponse } from '../http';
import { Unit } from './unit.model';

export async function unitsPromiseFilter(
  units: Unit[],
  getAll: (abortController: AbortController, isActive?: boolean) => Promise<HttpFetchResponse<Unit[]>>,
  setUnits: Dispatch<SetStateAction<Unit[]>>,
  inputValue: string,
  abortController: AbortController,
  isActive?: boolean
) {
  const text = inputValue.toLowerCase();

  let unitsFiltered = units?.filter((x) => x.name.toLowerCase().includes(text));

  if (!inputValue) {
    const { data } = await getAll(abortController, isActive);

    unitsFiltered = data;

    setUnits(unitsFiltered);
  }

  return unitsFiltered?.map((x) => ({ value: x.id, label: x.name }));
}
