import { Team } from '../team/team.model';
import { EntitySimpleResponse, PaginatedListResponse } from '../types';

export type Unit = {
  id: number;
  name: string;
  businessLine?: {
    name: string;
    id: number;
  };
  teams?: Team[];
  color: string;
  displayName: string;
  order: number;
  accounts: string;
  hasEmployeesWithoutTeam: boolean;
};

export const RESEARCH_UNIT_NAME = 'Research';
export const RESEARCH_UNIT_SHORT_NAME = 'RS';

export type UnitListResponse = PaginatedListResponse<UnitListItem>;

export type UnitListItem = {
  id: number;
  crmId: string;
  name: string;
  displayName: string;
  accounts: EntitySimpleResponse[];
  color: string;
  order: number;
  clientManager: EntitySimpleResponse;
  deliveryDirector: EntitySimpleResponse;
};

export type UnitSimple = Omit<Unit, 'businessLine' | 'teams'>;

export enum UnitTab {
  Unit = 0,
  Teams = 1,
  Funds = 2,
  Projects = 3
}
