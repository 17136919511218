import { Dispatch, SetStateAction } from 'react';
import { HttpFetchResponse, isAbortError, transformIntegerToStringFormatSync } from '~/app/shared';
import { FormSelectOption } from '../form';
import { Team } from './team.model';

export const NO_TEAM_TEAM: Team = {
  id: -1,
  alias: 'No Team',
  number: -1,
  isManagement: false
};

export function longTeamName(team: Team): string {
  if (team.alias) return team.alias;

  if (team.isManagement) {
    return team.manager.name;
  }

  if (!team?.unit) {
    return getTeamName(team.number);
  }

  if (!team.unit.businessLine) {
    return `${team.unit.name} - ${getTeamName(team.number)}`;
  }

  return `${team.unit.businessLine?.name} - ${team.unit.name} - ${getTeamName(team.number)}`;
}

export function shortTeamName(team: Team): string {
  if (!team) return '';
  if (team.id === NO_TEAM_TEAM.id) return NO_TEAM_TEAM.alias;

  return team.isManagement ? team.manager.name : getTeamName(team.number);
}

export function getTeamName(input: number): string {
  return transformIntegerToStringFormatSync(input, { preffix: 'Team ' });
}

export type TeamWithLabel = Team & {
  label: string;
};

export function groupAndSortTeams(teams: Team[]): TeamWithLabel[] {
  const teamsWithLabel = teamsToTeamsWithLabel(teams);

  return teamsWithLabel.sort((a, b) => a.label.localeCompare(b.label));
}

function teamsToTeamsWithLabel(teams: Team[]): TeamWithLabel[] {
  return teams.map((team) => ({ label: longTeamName(team), ...team }));
}

export async function teamsByUnitsPromiseFilter(
  teams: Team[],
  getByUnitId: (unitId: number, abortController) => Promise<HttpFetchResponse<Team[]>>,
  setTeams: Dispatch<SetStateAction<Team[]>>,
  inputValue: string,
  unitsIds: number[],
  abortController: AbortController
): Promise<FormSelectOption[]> {
  const text = inputValue?.toLowerCase();
  let teamsFiltered = teams?.filter((x) => longTeamName(x).toLowerCase().includes(text));

  if (!inputValue || unitsIds.length < 1) {
    try {
      const promises = unitsIds.map((x) => getByUnitId(x, abortController));
      const results = await Promise.all(promises);
      teamsFiltered = results.map((result) => result.data).flat();

      setTeams(teamsFiltered);
    } catch (error) {
      if (!isAbortError(error)) {
        console.error(error);
      }
    }
  }

  return teamsFiltered
    .map((x) => ({ value: x.id, label: longTeamName(x) }))
    .sort((a, b) => a.label.localeCompare(b.label));
}
